var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "px-5 ml-5 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('Printers')))]), _c('div', {
    staticClass: "text-right"
  }), _c('v-card-title', [_c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchPrinters,
      callback: function callback($$v) {
        _vm.searchPrinters = $$v;
      },
      expression: "searchPrinters"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersPrinters,
      "items": _vm.printers,
      "search": _vm.searchPrinters,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: "item.brand",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.brand.split('_').join(' ')))])];
      }
    }, {
      key: "item.model",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.model.split('_').join(' ')))])];
      }
    }, {
      key: "item.technology",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.technology))])];
      }
    }, {
      key: "item.build_volume",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.build_volume.depth) + " x ")]), _c('span', [_vm._v(_vm._s(item.build_volume.width) + " x ")]), _c('span', [_vm._v(_vm._s(item.build_volume.height) + " mm")])];
      }
    }, {
      key: "item.minimumSpacing",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(item.minimumSpacing) + " mm")])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.quantity))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "small": "",
            "disabled": !_vm.currentUserIsAdmin
          },
          on: {
            "click": function click($event) {
              return _vm.editPrinter(item.brand, item.model, item.technology, item.build_volume, item.quantity, item.minimumSpacing);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-pen ")])], 1)];
      }
    }], null, true)
  })], 1)], 1), _c('printer-configuration', {
    ref: "printerConfiguration",
    attrs: {
      "new-printer": _vm.newPrinter,
      "printer": _vm.printer,
      "brands-available": _vm.brandsAvailable,
      "models-available": _vm.modelsAvailable,
      "brand-chosen": _vm.brandChosen,
      "model-chosen": _vm.modelChosen,
      "technologies-and-materials": _vm.technologiesAndMaterials
    },
    on: {
      "modifySupplierPrinter": _vm.modifySupplierPrinter,
      "clearFormAddPrinter": _vm.clearFormAddPrinter,
      "getPrintersModels": function getPrintersModels($event) {
        return _vm.getPrintersModels($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }